.tabs {
  .tab-bar {
    width: 100%;
    border-bottom: 1px solid $border-gray;
    display: flex;
    flex-direction: row;
    .tab {
      // @include transition (all, 0.5s, linear);
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      padding: 16px 0;
      margin-right: 32px;
      position: relative;
      top: 2px;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      &.active-tab {
        font-family: $bold-font;
        border-bottom: 3px solid $active;
      }
    }
  }
  .tab-content {
    display: none;
    &.active-tab-content {
      display: block;
    }
  }
}
