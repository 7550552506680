.insight-row {
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  div {
    padding: 0 5px
  }
  &:hover {
    background-color: #f3f3f3;
    cursor: pointer;
  }
  .insight-blurb {
    p {
      font-size: 14px;
      font-family: $body-font;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: 0.25px;
      color: #000000;
    }
    h5 {
      text-transform: uppercase;
      margin-top: 10px;
      font-size: 12px;
      font-family: $bold-font;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
