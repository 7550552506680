.graph-set {
  padding: 50px 0;
  .graph-alert {
    border: solid 1px #ff9d40;
    background-color: rgba(255, 157, 64, 0.09);
    text-align: center;
    padding: 20px;
    margin-top: 30px;
  }
  .graph-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h3 {
      position: relative;
      .icon {
        position: absolute;
        right: -30px;
        top: -5px;
      }
    }
  }
  .metric {
    padding: 30px 0;
    .metric-title {
      font-size: 12px;
      line-height: 1.33;
      font-family: $body-font;
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
      margin: 0 0 4px 0;
    }
    .metric-value {
      font-size: 25px;
      font-family: $bold-font;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      margin: 0 0 4px 0;
      img {
        position: relative;
        top: 2px;
      }
    }
    .metric-subititle {
      font-size: 12px;
      font-family: $body-font;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: #000000;
      margin: 0 0 4px 0;
    }
  }
}

.bar-graph {
  width: 100%;
  h2 {
    padding: 30px 0;
  }
  .graph {
    position: relative;
    width: 100%;
    margin: 60px 0;
    .ticks {
      height: 100%;
      width: 60%;
      position: absolute;
      right: 0;
      display: flex;
      justify-content: space-between;
      .tickbox {
        position: relative;
        width: 1px;
        height: 100%;
        border-width:0 2px 0 0;
        border-style: dashed;
        border-color: #eee;
        overflow: visible;
        z-index: 1;
        .tick-label-top, .tick-label-bottom {
          color: #c2c2c2;
          display: inline-block;
          width: 100px;
          position: absolute;
          left: -50px;
          text-align: center;
          .strong-tick {
            position: absolute;
            width: 2px;
            height: 8px;
            background: rgba(0,0,0,0.6);
            left: 50px;
          }
        }
        .tick-label-top {
          top: -40px;
          .strong-tick {
            top: 28px;
          }
        }
        .tick-label-bottom {
          bottom: -40px;
          .strong-tick {
            bottom: 28px;
          }
        }
      }
    }
    .row {
      padding: 20px 0;
      background: #FFF;
      display: flex;
      flex-direction: column;
      position: relative;
      .data-row-tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        .tooltip {
          width: 295px;
          box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
          border: solid 1px #e3e3e3;
          background-color: #ffffff;
          position: absolute;
          top: 200px;
          left: 40px;
          padding: 30px 15px;
          display: block;
          z-index: 999;
        }
      }
      .insight-tip {
        position: absolute;
        top: 0;
        height: 100%;
        .insight-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          margin-top: -4px;
          top: 50%;
          left: -20px;
          padding: 0;
          cursor: pointer;
        }
        .insight-tooltip {
          width: 295px;
          box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
          border: solid 1px #e3e3e3;
          background-color: #ffffff;
          position: absolute;
          left: 0;
          top: 100%;
          padding: 30px 15px;
          display: none;
        }
        &.hover .insight-tooltip {
          display: block;
          z-index: 999;
        }
      }
      &.odd {
        background: #F7F8F9;
      }
      &.selected, &.selected.odd {
        background: #E6F4F7;
        border-right: 3px solid $active;
        z-index: 1;
      }
      .label-section {
        display: flex;
        flex: 2;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        width: 40%;
        position: relative;
        .bench-stat {
          position: absolute;
          right: 6px;
          top: 5px;
          margin: 0;
          font-size: 14px;
          font-family: $bold-font;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          text-align: right;
          color: #000000;
          .icon {
            width: 14px;
            height: 14px;
            img {
              width: 14px;
              height: 14px;
            }
          }
          &.reverse {
            .icon img {
              transform: rotate(180deg);
            }
          }
        }
        .graph-number {
          display: inline-block;
          padding-right: 10px;
        }
        .bar-label {
          display: inline-block;
          padding-right: 12px;
        }
        .graph-label {
          width: 300px;
          padding-left: 20px;
          font-size: 14px;
          font-family: $body-font;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: 0.25px;
          color: #000000;
          &.range-label {
            padding-left: 43px;
            opacity: 0.5;
            font-size: 14px;
            font-family: $body-font;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: 0.25px;
            color: #000000;
          }
          .name-label {
            display: inline-block;
            max-width: 235px;
            vertical-align: top;
          }
        }

        .graph-sub-label {
          color: #B0BCC4;
        }
      }
      .bar-section {
        display: flex;
        flex-direction: row;
        margin: 0;
        flex: 3;
        z-index: 99;
        align-items: center;

        .bar {
          height: 22px;
          background: #B0BCC4;
          min-width: 2px;
        }
        .bench {
          display: flex;
          flex: 0 1 auto;
          position: relative;
          height: 28px;
          flex-direction: row;
          align-items: center;
          min-width: 4px;
          .bench-label {
            padding: 0 12px;
            position: absolute;
            width: 100px;
            right: -100px;
          }
          &.reverse {
            .above-bench {
              flex: 1;
              height: 22px;
              background: #06C774;
            }
            .below-bench {
              flex: 1;
              height: 22px;
              background: #F24865;
            }
          }
        }
        .bar-line {
          flex: 0 1 auto;
          height: 28px;
          width: 2px;
          background-color: #000;
        }
        .above-bench {
          flex: 1;
          height: 22px;
          background: #F24865;
        }
        .below-bench {
          flex: 1;
          height: 22px;
          background: #06C774;
        }
      }
    }
  }

}
