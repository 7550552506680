@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
.programs .carousel-container .slick-next:before, .programs .carousel-container .slick-prev:before {
  content: '';
  display: none;
}

.programs {
  width: 100%;
  padding: 65px;
  margin: 65px 0 0;
  border-top: 1px solid #eaeaea;
  h3 {
    font-size: 16px;
    font-family: $bold-font;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .carousel-container {
    width: 100%;
    max-width: 940px;
    margin: auto;
    height: 350px;
    border: 1px solid #ECECEC;
    padding: 28px;
    .slick-arrow.slick-prev {
      z-index: 9;
    }
    .slick-dots li {
      width: 10px;
      button:before {
        font-size: 10px;
      }
    }
    .slide {
      text-align: center;
      .slide-img {
        display: inline-block;
        width: 30%;
        height: 220px;
        width: 220px;
        margin: 20px;
        vertical-align: top;
      }
      .slide-info {
        display: inline-block;
        text-align: left;
        width: 60%;
        height: 220px;
        width: 362px;
        margin: 20px;
        vertical-align: top;
        h2 {
          font-size: 25px;
          font-family: $bold-font;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.87);
        }
        p {
          font-size: 14px;
          font-family: $body-font;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          color: #000000;
        }
      }
    }
  }
}
