body {
  margin: 0;
  padding: 0;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h4, h5, span, p, a, div, label {
  font-family: $body-font;
}

h1, h2, h3 {
  font-family: $bold-font;
  font-weight: normal;
}

main {
  margin-top: 50px;
}

.app-container {
  display: flex;
  flex-direction: column;
  background: $light-grey;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  .app-content-wrap {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // padding-right: 58px;
    justify-content: flex-start;
  }
  .logged-out {
    padding: 22px;
    .logo img {
      height: 26px;
    }
  }
  .lo-info {
    text-align: center;
    .lo-modal {
      display: inline-block;
      text-align: left;
      width: 630px;
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
      padding: 65px;
      h2 {
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 50px;
      }
      a {
        width: 144px;
        padding: 10px;
        background-color: #45cae5;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 1.25px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .temp-link {
    display: block;
    font-size: 20px;
    padding: 14px;
  }
}
