/**
 * @license
 * MyFonts Webfont Build ID 3802426, 2019-08-29T15:11:40-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 *
 * Webfont: AvenirNextLTPro-Regular by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-regular/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3802426
 * Licensed pageviews: 500,000
 * Webfonts copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a053a");


@font-face {font-family: 'AvenirNextLTPro-Demi';src: url('assets/webfonts/3A053A_0_0.eot');src: url('assets/webfonts/3A053A_0_0.eot?#iefix') format('embedded-opentype'),url('assets/webfonts/3A053A_0_0.woff2') format('woff2'),url('assets/webfonts/3A053A_0_0.woff') format('woff'),url('assets/webfonts/3A053A_0_0.ttf') format('truetype');}


@font-face {font-family: 'AvenirNextLTPro-Regular';src: url('assets/webfonts/3A053A_1_0.eot');src: url('assets/webfonts/3A053A_1_0.eot?#iefix') format('embedded-opentype'),url('assets/webfonts/3A053A_1_0.woff2') format('woff2'),url('assets/webfonts/3A053A_1_0.woff') format('woff'),url('assets/webfonts/3A053A_1_0.ttf') format('truetype');}



$alert-high: #8b8b8b;
$alert-medium: #c0c0c0;
$alert-low: #f0f0f0;

$black: #000000;
$black-20: rgba(0, 0, 0, 0.2);
$divider-grey: #dddddd;
$light-grey: #f4f4f4;
$warm-grey: #979797;
$white: #ffffff;

$border-gray: #ECECEC;

$background-blue: #E6F4F7;
$active: #45CAE5;

$open-sans: 'Lato','Open Sans', sans-serif;

$body-font: 'AvenirNextLTPro-Regular';

$bold-font: 'AvenirNextLTPro-Demi';

$break-nav: 1795px;
$break-insight: 1550px;
