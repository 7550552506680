@import url(//hello.myfonts.net/count/3a053a);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3802426, 2019-08-29T15:11:40-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 *
 * Webfont: AvenirNextLTPro-Regular by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-regular/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3802426
 * Licensed pageviews: 500,000
 * Webfonts copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url(/static/media/3A053A_0_0.3a9a7cae.eot);
  src: url(/static/media/3A053A_0_0.3a9a7cae.eot?#iefix) format("embedded-opentype"), url(/static/media/3A053A_0_0.5c5938a8.woff2) format("woff2"), url(/static/media/3A053A_0_0.88857f83.woff) format("woff"), url(/static/media/3A053A_0_0.687be36e.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url(/static/media/3A053A_1_0.1a7ead2e.eot);
  src: url(/static/media/3A053A_1_0.1a7ead2e.eot?#iefix) format("embedded-opentype"), url(/static/media/3A053A_1_0.6102edf0.woff2) format("woff2"), url(/static/media/3A053A_1_0.9d68e56f.woff) format("woff"), url(/static/media/3A053A_1_0.0d0a3e6b.ttf) format("truetype"); }

body {
  margin: 0;
  padding: 0;
  font-family: "AvenirNextLTPro-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h4, h5, span, p, a, div, label {
  font-family: "AvenirNextLTPro-Regular"; }

h1, h2, h3 {
  font-family: "AvenirNextLTPro-Demi";
  font-weight: normal; }

main {
  margin-top: 50px; }

.app-container {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  height: 100%;
  width: 100%;
  min-height: 100vh; }
  .app-container .app-content-wrap {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    justify-content: flex-start; }
  .app-container .logged-out {
    padding: 22px; }
    .app-container .logged-out .logo img {
      height: 26px; }
  .app-container .lo-info {
    text-align: center; }
    .app-container .lo-info .lo-modal {
      display: inline-block;
      text-align: left;
      width: 630px;
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
      padding: 65px; }
      .app-container .lo-info .lo-modal h2 {
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87); }
      .app-container .lo-info .lo-modal p {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 50px; }
      .app-container .lo-info .lo-modal a {
        width: 144px;
        padding: 10px;
        background-color: #45cae5;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 1.25px;
        text-align: center;
        color: #ffffff; }
  .app-container .temp-link {
    display: block;
    font-size: 20px;
    padding: 14px; }

.logo {
  display: inline-block;
  vertical-align: middle;
  height: 26px; }

.nav-container {
  position: relative;
  width: 218px; }

.nav-bar {
  width: 260px;
  padding: 0;
  top: 96px;
  position: fixed;
  height: 100%;
  z-index: 999; }
  .nav-bar .side-nav-logo {
    display: none;
    padding: 24px;
    border-bottom: solid 1px #e6e6e6; }
    .nav-bar .side-nav-logo .side-nav-toggle {
      display: inline-block;
      font-size: 26px;
      cursor: pointer;
      padding-right: 20px;
      vertical-align: middle; }
    .nav-bar .side-nav-logo .logo {
      display: inline-block;
      vertical-align: middle;
      height: 26px; }
      .nav-bar .side-nav-logo .logo img {
        width: 148px;
        height: 26px; }
  .nav-bar .sign-out {
    margin: 25px 0px 0px 25px;
    border-top: solid 1px #e1e1e1; }
    .nav-bar .sign-out .username {
      width: 190px;
      font-size: 12px;
      line-height: 1.33;
      font-family: "AvenirNextLTPro-Regular";
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
      margin: 40px 0 3px 0;
      font-weight: normal; }
    .nav-bar .sign-out .nav-item {
      padding-top: 0;
      text-transform: capitalize; }
      .nav-bar .sign-out .nav-item a {
        padding: 12px 0px;
        color: #17bddf;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.2px;
        font-family: "AvenirNextLTPro-Demi"; }
  .nav-bar .nav-item {
    min-height: 40px; }
    .nav-bar .nav-item a, .nav-bar .nav-item .sub-nav {
      font-size: 15px;
      padding: 12px 12px 12px 25px;
      color: #333333;
      display: flex;
      flex-direction: row;
      align-items: center;
      letter-spacing: 1.2px;
      width: 100%; }
      .nav-bar .nav-item a.active, .nav-bar .nav-item .sub-nav.active {
        background-color: #E6F4F7;
        border-right: 3px solid #45CAE5; }
        .nav-bar .nav-item a.active span, .nav-bar .nav-item .sub-nav.active span {
          font-family: "AvenirNextLTPro-Demi";
          letter-spacing: 1.2px; }
    .nav-bar .nav-item .nav-icon {
      width: 24px;
      height: 24px; }

.dropdown {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  margin-left: 50px;
  position: relative; }
  .dropdown .dropdown-content {
    display: none; }
  .dropdown .dropdown-arrow {
    width: 1px;
    height: 1px;
    position: relative;
    top: 2px;
    border: 4px solid transparent;
    border-top: 4px solid #333;
    margin: 8px 10px; }
  .dropdown.open .dropdown-content {
    display: block;
    width: 100%; }
  .dropdown.open .dropdown-arrow {
    border-top: 4px solid transparent;
    border-bottom: 4px solid #333;
    top: -2px; }

.app-page-container {
  margin: 0 auto;
  display: flex; }

.page-container {
  background-color: #fff;
  margin: 96px 0 40px;
  padding: 85px 0 0;
  border: 1px solid #ECECEC;
  min-width: 1070px;
  width: 1070px; }
  .page-container .dash-page {
    margin: 0 65px; }
    .page-container .dash-page h1 {
      height: 46px;
      opacity: 0.8;
      font-size: 34px;
      font-family: "AvenirNextLTPro-Demi";
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #000000; }

.header {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  background-color: #fff;
  margin-bottom: 21px;
  border: solid 1px #e6e6e6;
  position: fixed;
  top: 0;
  z-index: 998; }
  .header .side-nav-toggle {
    display: none; }
  .header .logo img {
    height: 26px; }
  .header .insight, .header .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px; }
    .header .insight .icon, .header .date .icon {
      margin-right: 10px; }
      .header .insight .icon .badge span, .header .date .icon .badge span {
        width: 22px;
        height: 22px;
        align-content: center;
        line-height: 22px;
        font-family: "AvenirNextLTPro-Demi"; }
      .header .insight .icon img, .header .date .icon img {
        width: 26px;
        height: 26px; }

h3 .icon {
  vertical-align: middle; }

.icon {
  width: 28px;
  height: 28px;
  position: relative;
  overflow: visible;
  display: inline-block;
  cursor: pointer; }
  .icon img {
    width: 28px;
    height: 28px; }
  .icon .badge {
    min-width: 18px;
    text-align: center;
    border-radius: 50%;
    background-color: #45CAE5;
    color: #ffffff;
    font-family: "AvenirNextLTPro-Demi";
    font-size: 14px;
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 2px 0 0 2px; }
  .icon .tooltip {
    width: 295px;
    box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    position: absolute;
    padding: 30px 15px;
    top: 40px;
    left: 40px;
    z-index: 999; }
    .icon .tooltip p {
      font-size: 14px;
      font-family: "AvenirNextLTPro-Regular";
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: 0.25px;
      color: #000000; }

.insights-panel {
  width: 388px;
  min-width: 388px;
  margin: 96px auto 0px 10px;
  z-index: 99; }
  .insights-panel h2 {
    padding: 14px 0;
    margin: 3px 0; }
  .insights-panel .insight-container {
    border: 1px solid #ECECEC;
    background: #ffffff;
    position: fixed;
    width: 388px; }
    .insights-panel .insight-container .insight-list {
      max-height: 400px;
      overflow-y: auto;
      padding-bottom: 30px; }
    .insights-panel .insight-container h2 {
      margin: 30px 16px 18px; }
    .insights-panel .insight-container .close-button {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 20px; }
    .insights-panel .insight-container .insight-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 15px;
      border-bottom: solid 1px #eaeaea; }
      .insights-panel .insight-container .insight-row .insight-dot {
        min-width: 8px;
        max-width: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        padding: 0; }
      .insights-panel .insight-container .insight-row .insight-blurb {
        margin: 0 32px; }
        .insights-panel .insight-container .insight-row .insight-blurb p {
          font-size: 14px;
          font-family: "AvenirNextLTPro-Regular";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          color: #000000;
          margin: 0;
          margin-bottom: 16px; }
        .insights-panel .insight-container .insight-row .insight-blurb h5 {
          font-size: 12px;
          font-family: "AvenirNextLTPro-Demi";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 2px;
          color: rgba(0, 0, 0, 0.6);
          margin: 0; }
      .insights-panel .insight-container .insight-row:hover {
        background-color: #f3f3f3;
        cursor: pointer; }
    .insights-panel .insight-container .view-report {
      display: block;
      padding: 30px; }
    .insights-panel .insight-container .detail-bar {
      background-color: #fafafa;
      padding: 14px; }
      .insights-panel .insight-container .detail-bar .back-button {
        cursor: pointer; }
        .insights-panel .insight-container .detail-bar .back-button .icon, .insights-panel .insight-container .detail-bar .back-button span {
          display: inline-block;
          vertical-align: middle; }
      .insights-panel .insight-container .detail-bar .close-button {
        top: 14px;
        right: 14px; }
    .insights-panel .insight-container .detail-title {
      margin: 16px 30px; }
      .insights-panel .insight-container .detail-title h3 {
        font-size: 25px;
        font-family: "AvenirNextLTPro-Demi";
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 8px; }
      .insights-panel .insight-container .detail-title span {
        font-size: 12px;
        font-family: "AvenirNextLTPro-Demi";
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.6); }
    .insights-panel .insight-container .detail-table .detail-table-row {
      display: flex;
      flex-direction: row;
      padding: 12px 30px;
      border-bottom: 1px solid #f1f1f1; }
      .insights-panel .insight-container .detail-table .detail-table-row.odd {
        background: #FAFAFB; }
      .insights-panel .insight-container .detail-table .detail-table-row.detail-table-header {
        padding: 12px 0;
        margin: 0 30px; }
        .insights-panel .insight-container .detail-table .detail-table-row.detail-table-header div {
          text-transform: uppercase;
          font-size: 12px;
          font-family: "AvenirNextLTPro-Demi";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 2px;
          color: rgba(0, 0, 0, 0.6); }
      .insights-panel .insight-container .detail-table .detail-table-row div {
        font-size: 14px;
        font-family: "AvenirNextLTPro-Regular";
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: 0.25px;
        color: #000000; }
      .insights-panel .insight-container .detail-table .detail-table-row .detail-table-metric {
        flex: 1.5 1;
        text-align: left; }
      .insights-panel .insight-container .detail-table .detail-table-row .detail-table-value,
      .insights-panel .insight-container .detail-table .detail-table-row .detail-table-bench {
        flex: 1 1;
        text-align: right; }
        .insights-panel .insight-container .detail-table .detail-table-row .detail-table-value.insight-bench,
        .insights-panel .insight-container .detail-table .detail-table-row .detail-table-bench.insight-bench {
          font-family: "AvenirNextLTPro-Demi"; }
  .insights-panel .more-insight {
    position: absolute;
    bottom: 0;
    padding: 30px 20px;
    width: 100%;
    text-transform: capitalize;
    color: #0000ff;
    cursor: pointer; }

.insight-row {
  display: flex;
  flex-direction: row;
  padding: 10px 15px; }
  .insight-row div {
    padding: 0 5px; }
  .insight-row:hover {
    background-color: #f3f3f3;
    cursor: pointer; }
  .insight-row .insight-blurb p {
    font-size: 14px;
    font-family: "AvenirNextLTPro-Regular";
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.25px;
    color: #000000; }
  .insight-row .insight-blurb h5 {
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 12px;
    font-family: "AvenirNextLTPro-Demi";
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6); }

.tabs .tab-bar {
  width: 100%;
  border-bottom: 1px solid #ECECEC;
  display: flex;
  flex-direction: row; }
  .tabs .tab-bar .tab {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 16px 0;
    margin-right: 32px;
    position: relative;
    top: 2px;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6); }
    .tabs .tab-bar .tab.active-tab {
      font-family: "AvenirNextLTPro-Demi";
      border-bottom: 3px solid #45CAE5; }

.tabs .tab-content {
  display: none; }
  .tabs .tab-content.active-tab-content {
    display: block; }

.toggle {
  margin-left: 42px;
  cursor: pointer; }
  .toggle .label {
    color: rgba(0, 0, 0, 0.6);
    display: inline-block;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px; }
    .toggle .label.active {
      font-family: "AvenirNextLTPro-Demi"; }
  .toggle .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px; }
    .toggle .switch input {
      opacity: 0;
      width: 0;
      height: 0; }
    .toggle .switch .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 9px; }
    .toggle .switch .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 50%; }
    .toggle .switch input:checked + .slider {
      background-color: #45CAE5; }
    .toggle .switch input:focus + .slider {
      box-shadow: 0 0 1px #45CAE5; }
    .toggle .switch input:checked + .slider:before {
      -webkit-transform: translateX(17.5px);
      transform: translateX(17.5px); }

.programs .carousel-container .slick-next:before, .programs .carousel-container .slick-prev:before {
  content: '';
  display: none; }

.programs {
  width: 100%;
  padding: 65px;
  margin: 65px 0 0;
  border-top: 1px solid #eaeaea; }
  .programs h3 {
    font-size: 16px;
    font-family: "AvenirNextLTPro-Demi";
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
    margin-bottom: 30px; }
  .programs .carousel-container {
    width: 100%;
    max-width: 940px;
    margin: auto;
    height: 350px;
    border: 1px solid #ECECEC;
    padding: 28px; }
    .programs .carousel-container .slick-arrow.slick-prev {
      z-index: 9; }
    .programs .carousel-container .slick-dots li {
      width: 10px; }
      .programs .carousel-container .slick-dots li button:before {
        font-size: 10px; }
    .programs .carousel-container .slide {
      text-align: center; }
      .programs .carousel-container .slide .slide-img {
        display: inline-block;
        width: 30%;
        height: 220px;
        width: 220px;
        margin: 20px;
        vertical-align: top; }
      .programs .carousel-container .slide .slide-info {
        display: inline-block;
        text-align: left;
        width: 60%;
        height: 220px;
        width: 362px;
        margin: 20px;
        vertical-align: top; }
        .programs .carousel-container .slide .slide-info h2 {
          font-size: 25px;
          font-family: "AvenirNextLTPro-Demi";
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.87); }
        .programs .carousel-container .slide .slide-info p {
          font-size: 14px;
          font-family: "AvenirNextLTPro-Regular";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          color: #000000; }

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .controls .control-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between; }
    .controls .control-inputs .toggle-inputs {
      display: flex;
      flex-direction: row;
      position: relative;
      top: 40px;
      min-width: 467px; }

.date-input {
  width: 402px;
  box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
  border: solid 1px #e4e4e4;
  background-color: #fafafa;
  position: absolute;
  z-index: 999;
  right: 0; }
  .date-input .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: default; }
  .date-input .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer; }
  .date-input h3 {
    font-size: 25px;
    font-family: "AvenirNextLTPro-Demi";
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin: 40px 30px 0;
    margin-top: 40px !important; }
  .date-input .date-range-labels {
    display: flex;
    flex-direction: row; }
    .date-input .date-range-labels .date-range-label {
      flex: 1 1;
      padding: 15px 30px;
      font-size: 12px;
      font-family: "AvenirNextLTPro-Demi";
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase; }
  .date-input .date-radios {
    display: flex;
    flex-direction: row;
    height: 260px;
    width: 100%;
    background: #fff;
    position: relative; }
    .date-input .date-radios .date-fade {
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      z-index: 5; }
    .date-input .date-radios .date-border {
      width: 1px;
      height: 200px;
      box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.25);
      border-right: solid 1px #e4e4e4;
      margin-top: 30px; }
    .date-input .date-radios .date-selection {
      display: flex;
      flex-direction: row;
      flex: 1 1;
      padding: 30px; }
      .date-input .date-radios .date-selection .radioGroup {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        max-height: 162px;
        overflow-y: auto; }
        .date-input .date-radios .date-selection .radioGroup label span {
          display: inline-block;
          width: 60px;
          height: 40.5px;
          border-radius: 20px;
          font-size: 15px;
          font-family: "AvenirNextLTPro-Regular";
          font-style: normal;
          font-stretch: normal;
          line-height: 2.67;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
          cursor: pointer; }
        .date-input .date-radios .date-selection .radioGroup label input {
          display: none; }
          .date-input .date-radios .date-selection .radioGroup label input:checked + span {
            color: #ffffff;
            background-color: #45cae5;
            font-family: "AvenirNextLTPro-Demi";
            line-height: 3; }
          .date-input .date-radios .date-selection .radioGroup label input:disabled + span {
            color: #e5e5e5;
            cursor: not-allowed; }
  .date-input .date-input-footer {
    background: #fff;
    padding: 0 30px 30px; }
  .date-input .date-alert {
    border: solid 1px #ff9d40;
    padding: 10px 30px;
    text-align: center; }

.graph-set {
  padding: 50px 0; }
  .graph-set .graph-alert {
    border: solid 1px #ff9d40;
    background-color: rgba(255, 157, 64, 0.09);
    text-align: center;
    padding: 20px;
    margin-top: 30px; }
  .graph-set .graph-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .graph-set .graph-header h3 {
      position: relative; }
      .graph-set .graph-header h3 .icon {
        position: absolute;
        right: -30px;
        top: -5px; }
  .graph-set .metric {
    padding: 30px 0; }
    .graph-set .metric .metric-title {
      font-size: 12px;
      line-height: 1.33;
      font-family: "AvenirNextLTPro-Regular";
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
      margin: 0 0 4px 0; }
    .graph-set .metric .metric-value {
      font-size: 25px;
      font-family: "AvenirNextLTPro-Demi";
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      margin: 0 0 4px 0; }
      .graph-set .metric .metric-value img {
        position: relative;
        top: 2px; }
    .graph-set .metric .metric-subititle {
      font-size: 12px;
      font-family: "AvenirNextLTPro-Regular";
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: #000000;
      margin: 0 0 4px 0; }

.bar-graph {
  width: 100%; }
  .bar-graph h2 {
    padding: 30px 0; }
  .bar-graph .graph {
    position: relative;
    width: 100%;
    margin: 60px 0; }
    .bar-graph .graph .ticks {
      height: 100%;
      width: 60%;
      position: absolute;
      right: 0;
      display: flex;
      justify-content: space-between; }
      .bar-graph .graph .ticks .tickbox {
        position: relative;
        width: 1px;
        height: 100%;
        border-width: 0 2px 0 0;
        border-style: dashed;
        border-color: #eee;
        overflow: visible;
        z-index: 1; }
        .bar-graph .graph .ticks .tickbox .tick-label-top, .bar-graph .graph .ticks .tickbox .tick-label-bottom {
          color: #c2c2c2;
          display: inline-block;
          width: 100px;
          position: absolute;
          left: -50px;
          text-align: center; }
          .bar-graph .graph .ticks .tickbox .tick-label-top .strong-tick, .bar-graph .graph .ticks .tickbox .tick-label-bottom .strong-tick {
            position: absolute;
            width: 2px;
            height: 8px;
            background: rgba(0, 0, 0, 0.6);
            left: 50px; }
        .bar-graph .graph .ticks .tickbox .tick-label-top {
          top: -40px; }
          .bar-graph .graph .ticks .tickbox .tick-label-top .strong-tick {
            top: 28px; }
        .bar-graph .graph .ticks .tickbox .tick-label-bottom {
          bottom: -40px; }
          .bar-graph .graph .ticks .tickbox .tick-label-bottom .strong-tick {
            bottom: 28px; }
    .bar-graph .graph .row {
      padding: 20px 0;
      background: #FFF;
      display: flex;
      flex-direction: column;
      position: relative; }
      .bar-graph .graph .row .data-row-tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999; }
        .bar-graph .graph .row .data-row-tooltip .tooltip {
          width: 295px;
          box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
          border: solid 1px #e3e3e3;
          background-color: #ffffff;
          position: absolute;
          top: 200px;
          left: 40px;
          padding: 30px 15px;
          display: block;
          z-index: 999; }
      .bar-graph .graph .row .insight-tip {
        position: absolute;
        top: 0;
        height: 100%; }
        .bar-graph .graph .row .insight-tip .insight-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          margin-top: -4px;
          top: 50%;
          left: -20px;
          padding: 0;
          cursor: pointer; }
        .bar-graph .graph .row .insight-tip .insight-tooltip {
          width: 295px;
          box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
          border: solid 1px #e3e3e3;
          background-color: #ffffff;
          position: absolute;
          left: 0;
          top: 100%;
          padding: 30px 15px;
          display: none; }
        .bar-graph .graph .row .insight-tip.hover .insight-tooltip {
          display: block;
          z-index: 999; }
      .bar-graph .graph .row.odd {
        background: #F7F8F9; }
      .bar-graph .graph .row.selected, .bar-graph .graph .row.selected.odd {
        background: #E6F4F7;
        border-right: 3px solid #45CAE5;
        z-index: 1; }
      .bar-graph .graph .row .label-section {
        display: flex;
        flex: 2 1;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        width: 40%;
        position: relative; }
        .bar-graph .graph .row .label-section .bench-stat {
          position: absolute;
          right: 6px;
          top: 5px;
          margin: 0;
          font-size: 14px;
          font-family: "AvenirNextLTPro-Demi";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          text-align: right;
          color: #000000; }
          .bar-graph .graph .row .label-section .bench-stat .icon {
            width: 14px;
            height: 14px; }
            .bar-graph .graph .row .label-section .bench-stat .icon img {
              width: 14px;
              height: 14px; }
          .bar-graph .graph .row .label-section .bench-stat.reverse .icon img {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
        .bar-graph .graph .row .label-section .graph-number {
          display: inline-block;
          padding-right: 10px; }
        .bar-graph .graph .row .label-section .bar-label {
          display: inline-block;
          padding-right: 12px; }
        .bar-graph .graph .row .label-section .graph-label {
          width: 300px;
          padding-left: 20px;
          font-size: 14px;
          font-family: "AvenirNextLTPro-Regular";
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: 0.25px;
          color: #000000; }
          .bar-graph .graph .row .label-section .graph-label.range-label {
            padding-left: 43px;
            opacity: 0.5;
            font-size: 14px;
            font-family: "AvenirNextLTPro-Regular";
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: 0.25px;
            color: #000000; }
          .bar-graph .graph .row .label-section .graph-label .name-label {
            display: inline-block;
            max-width: 235px;
            vertical-align: top; }
        .bar-graph .graph .row .label-section .graph-sub-label {
          color: #B0BCC4; }
      .bar-graph .graph .row .bar-section {
        display: flex;
        flex-direction: row;
        margin: 0;
        flex: 3 1;
        z-index: 99;
        align-items: center; }
        .bar-graph .graph .row .bar-section .bar {
          height: 22px;
          background: #B0BCC4;
          min-width: 2px; }
        .bar-graph .graph .row .bar-section .bench {
          display: flex;
          flex: 0 1 auto;
          position: relative;
          height: 28px;
          flex-direction: row;
          align-items: center;
          min-width: 4px; }
          .bar-graph .graph .row .bar-section .bench .bench-label {
            padding: 0 12px;
            position: absolute;
            width: 100px;
            right: -100px; }
          .bar-graph .graph .row .bar-section .bench.reverse .above-bench {
            flex: 1 1;
            height: 22px;
            background: #06C774; }
          .bar-graph .graph .row .bar-section .bench.reverse .below-bench {
            flex: 1 1;
            height: 22px;
            background: #F24865; }
        .bar-graph .graph .row .bar-section .bar-line {
          flex: 0 1 auto;
          height: 28px;
          width: 2px;
          background-color: #000; }
        .bar-graph .graph .row .bar-section .above-bench {
          flex: 1 1;
          height: 22px;
          background: #F24865; }
        .bar-graph .graph .row .bar-section .below-bench {
          flex: 1 1;
          height: 22px;
          background: #06C774; }

.tint-screen {
  position: fixed;
  display: block;
  width: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.3); }

.button {
  display: inline-block;
  width: 144px;
  height: 36px;
  background-color: #45cae5;
  font-size: 14px;
  font-family: "AvenirNextLTPro-Demi";
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  text-transform: uppercase; }
  .button.hover, .button:hover {
    background-color: #3db8d1;
    color: #ffffff; }

@media screen and (max-width: 1550px) {
  .app-container .header .side-nav-toggle {
    display: inline-block;
    font-size: 26px;
    cursor: pointer;
    padding-right: 20px;
    vertical-align: middle; }
  .app-container .insights-panel {
    position: fixed;
    right: 30px;
    top: 96px;
    margin: 0 0px 0px 0px; }
    .app-container .insights-panel .insight-container {
      box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
      border: solid 1px #e4e4e4; }
  .app-container .nav-container {
    position: fixed;
    height: 100%;
    z-index: 999;
    left: -300px;
    -webkit-transition: left 0.3s;
    /* For Safari 3.1 to 6.0 */
    transition: left 0.3s;
    top: 0px; }
    .app-container .nav-container.open {
      left: 0px; }
    .app-container .nav-container .nav-bar {
      background-color: #fff;
      top: 0px; }
      .app-container .nav-container .nav-bar .side-nav-logo {
        display: block; }
  .app-container .tint-screen.open {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 998; } }

@media screen and (max-width: 1795px) {
  .app-container.insights-open .header .side-nav-toggle {
    display: inline-block;
    font-size: 26px;
    cursor: pointer;
    padding-right: 20px;
    vertical-align: middle; }
  .app-container.insights-open .nav-container {
    position: fixed;
    height: 100%;
    z-index: 999;
    left: -300px;
    -webkit-transition: left 0.3s;
    /* For Safari 3.1 to 6.0 */
    transition: left 0.3s;
    top: 0px; }
    .app-container.insights-open .nav-container.open {
      left: 0px; }
    .app-container.insights-open .nav-container .nav-bar {
      background-color: #fff;
      top: 0px; }
      .app-container.insights-open .nav-container .nav-bar .side-nav-logo {
        display: block; }
  .app-container.insights-open .tint-screen.open {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 998; } }

