.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .control-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    .toggle-inputs {
      display: flex;
      flex-direction: row;
      position: relative;
      top: 40px;
      min-width: 467px;
    }
  }
}
