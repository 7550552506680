.header {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  background-color: #fff;
  margin-bottom: 21px;
  border: solid 1px #e6e6e6;
  position: fixed;
  top: 0;
  z-index: 998;
  .side-nav-toggle {
    display: none;
  }
  // @media screen and (max-width: $break-nav) {
  //   .side-nav-toggle {
  //     display: inline-block;
  //     font-size: 26px;
  //     cursor: pointer;
  //     padding-right: 20px;
  //     vertical-align: middle;
  //   }
  // }
  .logo img {
    height: 26px;
  }
  .insight, .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px;
    .icon {
      margin-right: 10px;
      .badge {
        span {
          width: 22px;
          height: 22px;
          align-content: center;
          line-height: 22px;
          font-family: $bold-font;
        }
      }
      img {
        width: 26px;
        height: 26px;
      }
    }
  }
}
