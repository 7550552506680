.dropdown {
  cursor: pointer;
  user-select: none;
  display: inline-block;
  margin-left: 50px;
  position: relative;
  .dropdown-content {
    display: none;
  }
  .dropdown-arrow {
    width: 1px;
    height: 1px;
    position: relative;
    top: 2px;
    border: 4px solid transparent;
    border-top: 4px solid #333;
    margin: 8px 10px;
  }
  &.open {
    .dropdown-content {
      display: block;
      width: 100%;
    }
    .dropdown-arrow {
      border-top: 4px solid transparent;
      border-bottom: 4px solid #333;
      top: -2px;
    }
  }
}
