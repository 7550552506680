.insights-panel {
  width: 388px;
  min-width: 388px;
  margin: 96px auto 0px 10px;
  z-index: 99;
  // @media screen and (max-width: $break-insight) {
  //   position: fixed;
  //   right: 30px;
  //   top: 96px;
  //   margin: 0 0px 0px 0px;
  //   .insight-container {
  //     box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
  //     border: solid 1px #e4e4e4;
  //   }
  // }
  h2 {
    padding: 14px 0;
    margin: 3px 0;
  }
  .insight-container {
    border: 1px solid $border-gray;
    background: $white;
    position: fixed;
    width: 388px;
    .insight-list {
      max-height: 400px;
      overflow-y: auto;
      padding-bottom: 30px;
    }
    h2 {
      margin: 30px 16px 18px;
    }
    .close-button {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 20px;
    }
    .insight-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 15px;
      border-bottom: solid 1px #eaeaea;
      .insight-dot {
        min-width: 8px;
        max-width: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        padding: 0;
      }

      .insight-blurb {
        margin: 0 32px;
        p {
          font-size: 14px;
          font-family: $body-font;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          color: #000000;
          margin: 0;
          margin-bottom: 16px;
        }
        h5 {
          font-size: 12px;
          font-family: $bold-font;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 2px;
          color: rgba(0, 0, 0, 0.6);
          margin: 0;
        }
      }
      &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
      }
    }
    .view-report {
      display: block;
      padding: 30px;
    }
    .detail-bar {
      background-color: #fafafa;
      padding: 14px;
      .back-button {
        cursor: pointer;
        .icon, span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .close-button {
        top: 14px;
        right: 14px;
      }
    }
    .detail-title {
      margin: 16px 30px;
      h3 {
        font-size: 25px;
        font-family: $bold-font;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 8px;
      }
      span {
        font-size: 12px;
        font-family: $bold-font;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .detail-table {
      .detail-table-row {
        display: flex;
        flex-direction: row;
        padding: 12px 30px;
        border-bottom: 1px solid #f1f1f1;
        &.odd {
          background: #FAFAFB;
        }
        &.detail-table-header {
          padding: 12px 0;
          margin: 0 30px;
          div {
            text-transform: uppercase;
            font-size: 12px;
            font-family: $bold-font;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: 2px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        div {
          font-size: 14px;
          font-family: $body-font;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: 0.25px;
          color: #000000;
        }
        .detail-table-metric {
          flex: 1.5;
          text-align: left;
        }
        .detail-table-value,
        .detail-table-bench {
          flex: 1;
          text-align: right;
          &.insight-bench {
            font-family: $bold-font;
          }
        }
      }
    }
  }
  .more-insight {
    position: absolute;
    bottom: 0;
    padding: 30px 20px;
    width: 100%;
    text-transform: capitalize;
    color: #0000ff;
    cursor: pointer;
  }
}
