.button {
  display: inline-block;
  width: 144px;
  height: 36px;
  background-color: #45cae5;
  font-size: 14px;
  font-family: $bold-font;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  text-transform: uppercase;
  &.hover, &:hover {
    background-color: #3db8d1;
    color: #ffffff;
  }
}
