.toggle {
  margin-left: 42px;
  cursor: pointer;

  .label {
    color: rgba(0, 0, 0, 0.6);
    display: inline-block;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 6px;
    user-select: none;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;

    &.active {
      font-family: $bold-font;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 9px;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 50%;
    }
    input:checked + .slider {
      background-color: $active;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $active;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(17.5px);
      -ms-transform: translateX(17.5px);
      transform: translateX(17.5px);
    }
  }
}
