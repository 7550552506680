.logo {
  display: inline-block;
  vertical-align: middle;
  height: 26px;
}
.nav-container {
  position: relative;
  width: 218px;
}
.nav-bar {
  width: 260px;
  padding: 0;
  top: 96px;
  position: fixed;
  height: 100%;
  z-index: 999;

  .side-nav-logo {
    display: none;
    padding: 24px;
    border-bottom: solid 1px #e6e6e6;
    .side-nav-toggle {
      display: inline-block;
      font-size: 26px;
      cursor: pointer;
      padding-right: 20px;
      vertical-align: middle;
    }
    .logo {
      display: inline-block;
      vertical-align: middle;
      height: 26px;
      img {
        width: 148px;
        height: 26px;
      }
    }
  }
  // @media screen and (max-width: $break-nav) {
  //   background-color: #fff;
  //   top: 0px;
  // }

  .sign-out {
    margin: 25px 0px 0px 25px;
    border-top: solid 1px #e1e1e1;
    .username {
      width: 190px;
      font-size: 12px;
      line-height: 1.33;
      font-family: "AvenirNextLTPro-Regular";
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
      margin: 40px 0 3px 0;
      font-weight: normal;
    }
    .nav-item {
      padding-top: 0;
      a {
        padding: 12px 0px;
        color: #17bddf;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.2px;
        font-family: $bold-font;
      }
      text-transform: capitalize;
    }
  }
  .nav-item {
    min-height: 40px;
    a, .sub-nav {
      font-size: 15px;
      padding: 12px 12px 12px 25px;
      color: #333333;
      display: flex;
      flex-direction: row;
      align-items: center;
      letter-spacing: 1.2px;
      width: 100%;
      &.active {
        background-color: $background-blue;
        border-right: 3px solid $active;
        span {
          font-family: $bold-font;
          letter-spacing: 1.2px;
        }
      }
    }
    .nav-icon {
      width: 24px;
      height: 24px;
    }
  }
}
