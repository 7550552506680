.tint-screen {
  position: fixed;
  display: block;
  width: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s; /* For Safari 3.1 to 6.0 */
  transition: opacity 0.3s;
  background-color: rgba(0,0,0,0.3);
  // @media screen and (max-width: $break-nav) {
  //   &.open {
  //     // display: block;
  //     opacity: 0.5;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 998;
  //   }
  // }
}
