.app-container {
  @media screen and (max-width: $break-insight) {
    .header .side-nav-toggle {
      display: inline-block;
      font-size: 26px;
      cursor: pointer;
      padding-right: 20px;
      vertical-align: middle;
    }
    .insights-panel {
      position: fixed;
      right: 30px;
      top: 96px;
      margin: 0 0px 0px 0px;
      .insight-container {
        box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
        border: solid 1px #e4e4e4;
      }
    }
    .nav-container {
      position: fixed;
      height: 100%;
      z-index: 999;
      left: -300px;
      -webkit-transition: left 0.3s; /* For Safari 3.1 to 6.0 */
      transition: left 0.3s;
      top: 0px;
      &.open {
        left: 0px;
      }
      .nav-bar {
        background-color: #fff;
        top: 0px;
        .side-nav-logo {
          display: block;
        }
      }
    }
    .tint-screen.open {
      opacity: 0.5;
      width: 100%;
      height: 100%;
      z-index: 998;
    }
  }
  @media screen and (max-width: $break-nav) {
    &.insights-open {
      .header .side-nav-toggle {
        display: inline-block;
        font-size: 26px;
        cursor: pointer;
        padding-right: 20px;
        vertical-align: middle;
      }
      .nav-container {
        position: fixed;
        height: 100%;
        z-index: 999;
        left: -300px;
        -webkit-transition: left 0.3s; /* For Safari 3.1 to 6.0 */
        transition: left 0.3s;
        top: 0px;
        &.open {
          left: 0px;
        }
        .nav-bar {
          background-color: #fff;
          top: 0px;
          .side-nav-logo {
            display: block;
          }
        }
      }
      .tint-screen.open {
        opacity: 0.5;
        width: 100%;
        height: 100%;
        z-index: 998;
      }
    }
  }
}
