.date-input {
  width: 402px;
  box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
  border: solid 1px #e4e4e4;
  background-color: #fafafa;
  position: absolute;
  z-index: 999;
  right: 0;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: default;
  }
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  h3 {
    font-size: 25px;
    font-family: $bold-font;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin: 40px 30px 0;
    margin-top: 40px !important;
  }
  .date-range-labels {
    display: flex;
    flex-direction: row;
    .date-range-label {
      flex: 1;
      padding: 15px 30px;
      font-size: 12px;
      font-family: $bold-font;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
    }
  }
  .date-radios {
    display: flex;
    flex-direction: row;
    height: 260px;
    width: 100%;
    background: #fff;
    position: relative;
    .date-fade {
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      z-index: 5;
    }
    .date-border {
      width: 1px;
      height: 200px;
      box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.25);
      border-right: solid 1px #e4e4e4;
      margin-top: 30px;
    }
    .date-selection {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 30px;

      .radioGroup {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-height: 162px;
        overflow-y: auto;
        label {
          span {
            display: inline-block;
            width: 60px;
            height: 40.5px;
            border-radius: 20px;
            // font-family: AvenirNext;
            font-size: 15px;
            font-family: $body-font;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.67;
            letter-spacing: normal;
            text-align: center;
            color: #000000;
            cursor: pointer;
          }

          input {
            display: none;
            &:checked + span {
              color: #ffffff;
              background-color: #45cae5;
              font-family: $bold-font;
              line-height: 3;
            }
            &:disabled + span {
              color: #e5e5e5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
  .date-input-footer {
    background: #fff;
    padding: 0 30px 30px;
  }
  .date-alert {
    border: solid 1px #ff9d40;
    padding: 10px 30px;
    text-align: center;
  }
}
