h3 .icon {
  vertical-align: middle;
}
.icon {
  width: 28px;
  height: 28px;
  position: relative;
  overflow: visible;
  display: inline-block;
  cursor: pointer;
  img {
    width: 28px;
    height: 28px;
  }
  .badge {
    min-width: 18px;
    text-align: center;
    border-radius: 50%;
    background-color: $active;
    color: $white;
    font-family: $bold-font;
    font-size: 14px;
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 2px 0 0 2px;
  }
  .tooltip {
    width: 295px;
    box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    position: absolute;
    padding: 30px 15px;
    top: 40px;
    left: 40px;
    z-index: 999;
    p {
      font-size: 14px;
      font-family: $body-font;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: 0.25px;
      color: #000000;
    }
  }
}
