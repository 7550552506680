.app-page-container {
  margin: 0 auto;
  display: flex;
}
.page-container {
  background-color: #fff;
  margin: 96px 0 40px;
  padding: 85px 0 0;
  border: 1px solid $border-gray;
  min-width: 1070px;
  width: 1070px;
  .dash-page {
    margin: 0 65px;
    h1 {
      height: 46px;
      opacity: 0.8;
      font-size: 34px;
      font-family: $bold-font;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #000000;
    }
  }
}
